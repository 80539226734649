<template>
  <div class="coupon-result">
    <div class="wrapper" v-if="$store.state.account.loggedIn">
      <div class="image" v-if="state.code || state.loaded">
        <div class="wrapper" v-if="state.code === 'CONFLICT_EVENT'">
          <span class="img forbidden" :style="{backgroundImage: `url(/assets/img/event.hit-and-hide.forbidden.svg)`}"></span>
          <span class="d-block">{{ state.message }}</span>
        </div>
        <div class="wrapper" v-else>
          <span style="background-image: url(/assets/img/event.hit-and-hide.published.svg)" aria-label="쿠폰 발급 완료" class="img"></span>
          <span>쿠폰이 발급되었습니다.</span>
        </div>
        <!--        <img class="img" v-else :src="`/assets/img/event.hit-and-hide.${modalParams.eventId}.${modalParams.point}.jpg`"/>-->
      </div>
      <div class="actions" v-if="state.loaded">
        <router-link :to="modalParams.redirectUrl" v-if="modalParams.redirectUrl">
          <button class="btn btn-dark">기획전으로 이동</button>
        </router-link>
        <router-link to="/mypage/coupon">
          <button class="btn btn-point" @click="destroy()">마이페이지로 이동</button>
        </router-link>
      </div>
    </div>
    <Loading :opaque="true" v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Loading from "@/components/Loading.vue";

function Component(initialize) {
  this.name = "modalCouponResult";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Loading},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      store.commit("tuneModal", {component, size: "sm"});

      if (!modalParams.eventId || !modalParams.token) {
        return store.commit("closeModal", {
          name: component.name,
          onClose() {
            store.commit("setSwingMessage", "잘못된 접근입니다.");
          }
        });
      }

      state.loaded = false;
      http.post(`/api/events/${modalParams.eventId}/${modalParams.token}`).then(() => {
        state.loaded = true;
      }).catch((error) => {
        state.loaded = true;
        state.code = error.response.data.code;
        state.message = error.response.data.message;
      });
    });

    const state = reactive({
      loaded: false,
      code: "",
      message: "",
    });

    const modalParams = store.getters.modalParams(component);

    const destroy = () => {
      store.commit("setPopEvent", {
        eventId: 1,
        $parent: document.getElementById("pagePartnerRewardPartnerReward"),
        destroy: true,
      });
    };

    return {component, state, modalParams, destroy};
  }
});
</script>

<style lang="scss" scoped>
.coupon-result {
  > div {
    background: #fff;

    > .image {
      .wrapper {
        display: flex;
        flex-direction: column;
        gap: $px20;
        justify-content: center;
        align-items: center;
        padding: $px56 0;

        > .img {
          width: 100%;
          padding-top: 8%;

          &.forbidden {
            padding-top: 16%;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;

      > a {
        width: 100%;

        .btn {
          width: 100%;
          height: $px60;
          border-radius: 0;
          font-size: $px14;
        }
      }
    }
  }

  @media (max-width: 991px) {
    > .container {
      > .image {
        .forbidden {
          > span {
            font-size: $px16;
          }
        }
      }

      > .actions {
        > a .btn {
          font-size: $px14;
          height: $px60;
        }
      }
    }
  }
}
</style>